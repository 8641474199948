import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: `"proxima-nova", "Roboto", "Helvetica", "Arial", sans-serif`
  },
  palette: {
    primary: {
      main: '#4692c4'
    },
    secondary: {
      main: '#96B57A'
    }
  }
});

export default theme;
