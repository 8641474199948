import Grid from '@material-ui/core/Grid';
import React from 'react';

interface IProps {
  children: React.ReactNode;
}

/**
 * Basic Layout
 * @param props Properties passed from parent component
 */

export default function Layout(props: IProps) {
  return (
    <main className="wrapper">
      <Grid container spacing={3} justify="center" alignItems="center">
        <Grid item xs={12} sm={10} md={6} lg={5} xl={4}>
          {props.children}
        </Grid>
      </Grid>
    </main>
  );
}
