export type IPolicy = 'organizationView';

const superAdmin = 'superAdmin';
const admin = 'admin';

const policies = {
  organizationView: [superAdmin, admin]
};

export default policies;
