import { gql } from '@apollo/client';

export const ORGANIZATIONS = gql`
  query organizations($search: String, $subType: String, $first: Int, $after: String) {
    organizations(search: $search, subType: $subType, first: $first, after: $after) {
      nodes {
        id
        name
        portal {
          name
          logo
        }
      }
      pageInfo {
        hasMore
        nextCursor
      }
    }
  }
`;

export const PAYEES = gql`
  query payees($search: String, $first: Int, $after: String) {
    payees(search: $search, first: $first, after: $after) {
      nodes {
        id
        altId
        company
        name
        organization {
          name
        }
      }
      pageInfo {
        hasMore
        nextCursor
      }
    }
  }
`;

export const PORTAL_BY_ALIAS = gql`
  query portalByAlias($alias: String!) {
    portalByAlias(alias: $alias) {
      alias
      name
      logo
    }
  }
`;
