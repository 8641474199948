import { useLazyQuery } from '@apollo/client';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import qs from 'query-string';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';

import Alert from '../../components/Alert';
import GraphqlAlert from '../../components/GraphqlAlert';
import config from '../../config';
import { AUTHENTICATE_EXACTUALS_WITH_AZURE } from '../../graphql/auth';

const useStyles = makeStyles({
  button: { padding: '12px 36px' },
  subtitle: { paddingBottom: 24 }
});

/**
 * Renders admin login form
 */

export default function Accounts() {
  const classes = useStyles();
  const location = useLocation();
  const { code, error, errorDescription } = qs.parse(location.search);
  const [query, results] = useLazyQuery(AUTHENTICATE_EXACTUALS_WITH_AZURE);
  const sso = _.find(config.sso, ['grantType', 'azure-ad']);
  const redirectUrl = `${document.location.origin}/admin/accounts`;
  const link = qs.stringifyUrl({
    url: `https://login.microsoftonline.com/${sso?.tenantId}/oauth2/v2.0/authorize`,
    query: {
      client_id: sso?.clientId,
      prompt: 'select_account',
      redirect_uri: redirectUrl,
      response_mode: 'query',
      response_type: 'code',
      scope: sso?.clientScope
    }
  });

  React.useEffect(() => {
    document.title = 'Paymenthub - Exactuals Admin Login';
  }, []);

  // Query GraphQL for Token
  React.useEffect(() => {
    if (error) {
      // An error from Azure was received and the error message will display below
      return;
    }

    if (!code) {
      /**
       * Payment Client links directly to this page without code
       * To prevent touching Payment Client code we simply redirect the admin to the portals page
       * Safari requires a full page reload for the cookie to be set properly
       */
      window.location.href = '/admin/portals/organizations';
      return;
    }

    const variables = { code, redirectUrl };
    query({ variables });
  }, [code, error, errorDescription, query, redirectUrl]);

  // Handle GraphQL Results
  React.useEffect(() => {
    if (!results.data?.authenticateExactualsWithAzure) {
      return;
    }

    const cookies = new Cookies();
    const { maxAge, phidToken } = results.data.authenticateExactualsWithAzure;
    cookies.set('phid', phidToken, { path: '/', maxAge, sameSite: 'strict', secure: true });

    // Safari requires a full page reload for the cookie to be set properly
    window.location.href = '/admin/portals/organizations';
  }, [results]);

  return (
    <form>
      <Typography className={classes.subtitle} variant="body1">
        Log in with Azure Active Directory
      </Typography>
      <GraphqlAlert error={results.error} />
      {errorDescription && <Alert severity="error">{errorDescription}</Alert>}
      {results.loading && <CircularProgress color="primary" />}
      {(!code || (results.called && !results.loading && !results.data)) && (
        <Button data-test="submit-login" className={classes.button} color="primary" href={link} variant="contained">
          Login
        </Button>
      )}
    </form>
  );
}
