import { ApolloClient, ApolloProvider, createHttpLink, GraphQLRequest, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import React from 'react';
import Cookies from 'universal-cookie';
import { onError, ErrorResponse } from '@apollo/client/link/error';
import config from '../config';

interface IHeaders {
  authorization?: string;
}
interface IProps {
  children: React.ReactNode;
}

/**
 * Sets authorization header is user is logged in
 * @param operation operation object to modify request
 * @param prevContext Context received from previous request
 */

function setter(operation: GraphQLRequest, prevContext: any) {
  const cookie = new Cookies();
  const authorization: string | undefined = cookie.get('phid');
  const headers: IHeaders = {};
  const context = { headers };

  if (authorization) {
    context.headers.authorization = authorization;
  }

  return context;
}

/**
 * Handles Errors recieved from response
 * @param errorResponse Response from request containing errors
 */

function errorHandler(errorResponse: ErrorResponse) {
  if (errorResponse.graphQLErrors) {
    errorResponse.graphQLErrors.map((e) => console.log(`[GraphQL error]: Message: ${e.message}, Location: ${e.locations}, Path: ${e.path}`));
  }

  if (errorResponse.networkError) {
    console.log(`[Network error]: ${errorResponse.networkError}`);
  }
}

function ApolloContextProvider(props: IProps) {
  const cache = new InMemoryCache();
  const httpLink = createHttpLink({ uri: config.graphql });
  const authLink = setContext(setter);
  const errorLink = onError(errorHandler);
  const link = authLink.concat(errorLink).concat(httpLink);
  const client = new ApolloClient({ cache, link });

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}

export default ApolloContextProvider;
