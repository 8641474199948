import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import qs from 'query-string';
import React from 'react';
import { useLocation } from 'react-router-dom';

import config from '../../config';

const useStyles = makeStyles({
  button: { padding: '12px 36px' },
  subtitle: { paddingBottom: 24 }
});

/**
 * Renders admin login form
 */

export default function Login() {
  const classes = useStyles();
  const location = useLocation();
  const { autosubmit } = qs.parse(location.search);
  const sso = _.find(config.sso, ['grantType', 'azure-ad']);
  const link = qs.stringifyUrl({
    url: `https://login.microsoftonline.com/${sso?.tenantId}/oauth2/v2.0/authorize`,
    query: {
      client_id: sso?.clientId,
      prompt: 'select_account',
      redirect_uri: `${document.location.origin}/admin/accounts`,
      response_mode: 'query',
      response_type: 'code',
      scope: sso?.clientScope
    }
  });
  
  React.useEffect(() => {
    document.title = 'Paymenthub - Exactuals Admin Login';
  }, []);

  React.useEffect(() => {
    if (autosubmit) {
      window.location.href = link;
    }
  }, [autosubmit, link]);

  return (
    <form>
      <Typography className={classes.subtitle} variant="body1">
        Log in with Exactuals/CNB Azure Active Directory
      </Typography>
      <Button data-test="submit-login" className={classes.button} color="primary" href={link} variant="contained">
        Login
      </Button>
    </form>
  );
}
