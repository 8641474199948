import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import Layout from '../components/layout';
import Admin from './exactuals';
import Azure from './azure';
import Error404 from './NoMatch';

/**
 * Renders layout and top level routes
 * We can move the layout to the low level routes to
 * allow for various layouts by view
 */

export default function Router() {
  const location = useLocation();

  return (
    <Layout>
      <Switch>
        <Redirect exact from="/" to="/admin/login" />
        <Redirect exact from="/admin" to="/admin/login" />
        <Route component={Admin} path="/admin" />
        <Route component={Azure} path="/:alias/v2/sso/active-directory/:grantType" />
        <Route component={Error404} exact path="/error/404" />
        <Redirect to={`/error/404?path=${location.pathname}`} />
      </Switch>
    </Layout>
  );
}
