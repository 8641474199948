import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';

import Organizations from './Organizations';
import Groups from './Groups';
import Payees from './Payees';

interface IRouterParams {
  type?: string;
}

/**
 * Renders organization and payee tabs with admin portal router
 */

export default function Portals() {
  const [tabIndex, setTabIndex] = React.useState(0);
  const history = useHistory();
  const routerParams: IRouterParams = useParams();

  const handleClick = (path: string) => () => {
    history.push(path);
  };

  React.useEffect(() => {
    if (routerParams.type === 'payees') {
      setTabIndex(2);
      return;
    }

    if (routerParams.type === 'groups') {
      setTabIndex(1);
      return;
    }

    setTabIndex(0);
  }, [routerParams.type]);

  return (
    <React.Fragment>
      <Tabs indicatorColor="primary" variant="fullWidth" value={tabIndex}>
        <Tab label="Payers" onClick={handleClick('/admin/portals/organizations')} />
        <Tab label="Groups" onClick={handleClick('/admin/portals/groups')} />
        <Tab label="Payees" onClick={handleClick('/admin/portals/payees')} />
      </Tabs>
      <Switch>
        <Route component={Organizations} path="/admin/portals/organizations" />
        <Route component={Groups} path="/admin/portals/groups" />
        <Route component={Payees} exact path="/admin/portals/payees" />
      </Switch>
    </React.Fragment>
  );
}
