import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Redirect, Route, Switch, useLocation, useHistory } from 'react-router-dom';

import PrivateRoute from '../../components/PrivateRoute';
import Logo from '../../svg/Logo';
import RedirectHandler from './Accounts';
import Login from './Login';
import Portal from './portal';

const useStyles = makeStyles({
  header: { textAlign: 'center' },
  logo: { width: '40%', height: '40%' },
  content: { textAlign: 'center', minHeight: 100 },
  actions: { display: 'block', textAlign: 'center' }
});

/**
 * Renders admin login form
 */

export default function AdminLogin() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const currentYear = new Date().getFullYear();

  const handleClick = () => {
    history.push('/admin/login');
  };

  return (
    <Card>
      <CardHeader
        className={classes.header}
        title={
          <div onClick={handleClick}>
            <Logo className={classes.logo} />
          </div>
        }
      />
      <CardContent className={classes.content}>
        <Switch>
          <Route component={Login} exact path="/admin/login" />
          <Route component={RedirectHandler} exact path="/admin/accounts" />
          <PrivateRoute component={Portal} path="/admin/portals/:type" />
          <Redirect to={`/error/404?path=${location.pathname}`} />
        </Switch>
      </CardContent>
      <CardActions className={classes.actions}>
        <Typography variant="caption" component="div">
            &copy; {currentYear} Exactuals, LLC
        </Typography>
      </CardActions>
    </Card>
  );
}
