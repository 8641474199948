import { useLazyQuery } from '@apollo/client';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import React from 'react';
import Cookies from 'universal-cookie';

import Alert from '../../../components/Alert';
import GraphqlAlert from '../../../components/GraphqlAlert';
import config from '../../../config';
import AssetUrl from '../../../config/assets';
import { AUTHENTICATE_EXACTUALS_ADMIN_AS_PAYER } from '../../../graphql/auth';
import { ORGANIZATIONS } from '../../../graphql/portals';

const useStyles = makeStyles({
  center: { margin: 'auto' },
  search: { marginTop: 12, marginBottom: 12 }
});

interface IProps {
  search?: string | null;
}

interface IPortal {
  alias: string;
  logo: string;
  name: string;
}

interface IOrganization {
  name: string;
  id: string;
  portal?: IPortal;
}

interface IItem extends IOrganization {
  authenticationInProgress: boolean;
  setAuthenticationInProgress: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Renders organization
 * @param props Properties from parent component
 */

function Organization(props: IItem) {
  const [query, results] = useLazyQuery(AUTHENTICATE_EXACTUALS_ADMIN_AS_PAYER, { fetchPolicy: 'no-cache' });

  // Handle GraphQL Results
  React.useEffect(() => {
    if (results.loading) {
      props.setAuthenticationInProgress(true);
    }

    if (results.error) {
      props.setAuthenticationInProgress(false);
    }

    if (!results.data?.authenticateExactualsAdminAsPayer) {
      return;
    }

    const { alias, maxAge, phidToken } = results.data.authenticateExactualsAdminAsPayer;
    const cookies = new Cookies();
    cookies.set('phid', phidToken, { path: '/', maxAge, sameSite: 'strict' });

    window.location.href = `${config.redirectAfterAuth}/${alias}`;
  }, [results, props]);

  const handleClick = (organizationId: string) => () => {
    const variables = { organizationId };
    query({ variables });
  };

  return (
    <React.Fragment>
      {results.loading && <LinearProgress color="secondary" />}
      {results.error && <Alert severity="error">{results.error?.message}</Alert>}
      <ListItem
        key={props.id}
        data-test="organization-list-item"
        divider
        button
        onClick={handleClick(props.id)}
        disabled={props.authenticationInProgress}
      >
        {props.portal?.logo && (
          <ListItemAvatar>
            <Avatar alt={props.name} src={AssetUrl(props.portal.logo)} />
          </ListItemAvatar>
        )}
        {!props.portal?.logo && (
          <ListItemAvatar>
            <Avatar alt={props.name}>{props.name ? props.name.charAt(0).toUpperCase() : '?'}</Avatar>
          </ListItemAvatar>
        )}
        <ListItemText
          primary={props.name || 'Unknown'}
          secondary={
            <React.Fragment>
              ID: {props.id}
              <br />
              Portal: {props.portal?.name}
            </React.Fragment>
          }
        />
      </ListItem>
    </React.Fragment>
  );
}

/**
 * Renders list of organizations
 *
 * Conditional style was applied to the "CircularProgress", rather than render/no-render, to maintain a consistent height while fetching more data.
 * The "render/no-render" was causing a shifting page height while fetching data
 */

export default function Groups(props: IProps) {
  const classes = useStyles();
  const [authenticationInProgress, setAuthenticationInProgress] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState<string>();
  const [query, results] = useLazyQuery(ORGANIZATIONS, { errorPolicy: 'all' });
  const [organizations, setOrganizations] = React.useState<IOrganization[]>([]);
  const [nextCursor, setNextCursor] = React.useState<string | undefined | null>();

  React.useEffect(() => {
    document.title = 'Paymenthub - Groups';
  }, []);

  React.useEffect(() => {
    const variables = { search, subType: 'group' };
    query({ variables });
  }, [search, query]);

  React.useEffect(() => {
    if (!results.data?.organizations) {
      return;
    }

    setOrganizations(results.data.organizations.nodes);
    setNextCursor(results.data.organizations.pageInfo.nextCursor);
  }, [results]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value);
  }

  function fetchMore() {
    if (!nextCursor || !results.fetchMore) {
      return;
    }

    setLoading(true);
    results.fetchMore({
      variables: { after: nextCursor },
      updateQuery: (prev, options) => {
        setLoading(false);
        if (!options.fetchMoreResult || !options.fetchMoreResult.organizations) {
          setNextCursor(null);
          return;
        }

        setOrganizations([...organizations, ...options.fetchMoreResult.organizations.nodes]);
        setNextCursor(options.fetchMoreResult.organizations.pageInfo.nextCursor);
      }
    });
  }

  window.onscroll = _.debounce(() => {
    if (window.innerHeight + document.documentElement.scrollTop >= document.body.scrollHeight) {
      fetchMore();
    }
  }, 100);

  return (
    <React.Fragment>
      <TextField
        autoFocus
        className={classes.search}
        fullWidth
        onChange={handleChange}
        placeholder="Search for a group"
        value={search || ''}
        variant="outlined"
      />
      <List>
        <GraphqlAlert error={results.error} />
        {!results.loading &&
          organizations.map((organization, index) => (
            <Organization
              {...organization}
              authenticationInProgress={authenticationInProgress}
              key={index}
              setAuthenticationInProgress={setAuthenticationInProgress}
            />
          ))}
        {results.called && _.isEmpty(organizations) && !results.error && !results.loading && (
          <ListItem divider>
            <ListItemText primary="No records found. Please modify search criteria." />
          </ListItem>
        )}
        {nextCursor && !results.loading && !loading && (
          <ListItem>
            <Button variant="outlined" onClick={fetchMore} className={classes.center} color="primary">
              Load More
            </Button>
          </ListItem>
        )}
        {(results.loading || loading) && (
          <ListItem>
            <CircularProgress color="primary" className={classes.center} />
          </ListItem>
        )}
      </List>
    </React.Fragment>
  );
}
