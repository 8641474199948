import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export default function Logo(props: IProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 387.7 63">
      <g>
        <path
          fill="#96B57A"
          d="M73.3,46.2V14.9h13.8c6.6,0,10.2,4.5,10.2,9.8c0,5.3-3.7,9.8-10.2,9.8h-8.3v11.7H73.3z M91.7,24.7    c0-3-2.2-5-5.3-5h-7.6v9.9h7.6C89.5,29.6,91.7,27.6,91.7,24.7z"
        />
        <path fill="#96B57A" d="M121.6,46.2l-2.3-6.1h-14.4l-2.3,6.1h-6.3l12.3-31.3h6.9l12.3,31.3H121.6z M112.1,20.4l-5.6,14.9h11.3    L112.1,20.4z" />
        <path fill="#96B57A" d="M138.3,46.2v-13l-12.2-18.3h6.3l8.6,13.4l8.6-13.4h6.3l-12.1,18.3v13H138.3z" />
        <path fill="#96B57A" d="M187.5,46.2V22L178,46.2h-2.3L166,22v24.2h-5.5V14.9h7.8l8.6,21.5l8.5-21.5h7.8v31.3H187.5z" />
        <path fill="#96B57A" d="M200.9,46.2V14.9h21.5v4.8h-16v8.1H222v4.8h-15.7v8.7h16v4.8H200.9z" />
        <path fill="#96B57A" d="M251,46.2l-16.4-22.4v22.4h-5.5V14.9h5.6l16,21.7V14.9h5.5v31.3H251z" />
        <path fill="#96B57A" d="M271.7,46.2V19.7h-9.5v-4.8h24.5v4.8h-9.5v26.5H271.7z" />
        <path fill="#96B57A" d="M315.1,46.2V31.8h-18.3v14.4h-3.9V14.9h3.9v13.5h18.3V14.9h3.9v31.3H315.1z" />
        <path
          fill="#96B57A"
          d="M327.5,34V14.9h3.9v19.1c0,5.7,3.1,9.4,8.8,9.4c5.8,0,8.9-3.6,8.9-9.4V14.9h3.9V34    c0,7.8-4.3,12.8-12.8,12.8C331.8,46.8,327.5,41.7,327.5,34z"
        />
        <path
          fill="#96B57A"
          d="M361.6,46.2V14.9h14c5.5,0,8.8,3.3,8.8,8c0,3.9-2.6,6.6-5.5,7.2c3.3,0.5,6.1,3.9,6.1,7.7    c0,5-3.3,8.5-9.1,8.5H361.6z M380.3,23.4c0-2.8-1.9-5.1-5.4-5.1h-9.4v10.1h9.4C378.4,28.4,380.3,26.3,380.3,23.4z M380.9,37.3    c0-2.9-2-5.4-5.8-5.4h-9.6v10.8h9.6C378.7,42.7,380.9,40.7,380.9,37.3z"
        />
      </g>
      <g>
        <circle fill="none" stroke="#96B57A" strokeWidth="2" strokeMiterlimit="10" cx="30.5" cy="31.6" r="28.5" />
        <g>
          <circle fill="#96B57A" cx="30.5" cy="31.6" r="20" />
          <g>
            <path
              fill="#FFFFFF"
              d="M29.5,39.6c-2.6-0.2-4.5-1.2-5.9-2.6l1.9-2.8c0.9,1,2.3,1.8,4,2.1v-3.9c-2.6-0.6-5.4-1.6-5.4-5      c0-2.5,2-4.7,5.4-5v-2.3h2.3v2.4c2,0.2,3.7,1,5.1,2.2l-2,2.6c-0.9-0.8-2-1.3-3.1-1.6v3.4c2.6,0.6,5.5,1.6,5.5,5.1      c0,2.8-1.8,4.9-5.5,5.3v2.2h-2.3V39.6z M29.5,28.6v-3c-1.1,0.1-1.7,0.8-1.7,1.6C27.8,27.9,28.5,28.3,29.5,28.6z M31.8,33.1v3.3      c1.3-0.2,1.9-1,1.9-1.8C33.7,33.8,32.9,33.4,31.8,33.1z"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
