import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import _ from 'lodash';
import policy, { IPolicy } from '../config/authorizationPolicy';

interface IProps extends RouteProps {
  policy?: IPolicy;
}

/**
 * Determines user's authorization and renders route or redirects
 * @param props Routes properties
 */

export default function PrivateRoute(props: IProps) {
  const cookies = new Cookies();
  const phid = cookies.get('phid');

  // Update later We should get the user's roles from cache or api
  const userRoles = ['admin'];
  const authorizedRoles = props.policy ? policy[props.policy] : [];
  const intersection = _.intersection(authorizedRoles, userRoles);

  // If no token exists then redirect to login
  if (!phid) {
    return <Redirect to="/admin/login" />;
  }

  // If user's roles do not intersect with authorized roles then redirect to login
  if (props.policy && _.isEmpty(intersection)) {
    return <Redirect to="/admin/login" />;
  }

  return <Route {...props} />;
}
