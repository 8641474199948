import { useQuery } from '@apollo/client';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import qs from 'query-string';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import GraphqlAlert from '../../components/GraphqlAlert';
import config from '../../config';
import { PORTAL_BY_ALIAS } from '../../graphql/portals';

const useStyles = makeStyles({
  button: { padding: '12px 36px' },
  subtitle: { paddingBottom: 24 }
});

interface IParams {
  alias: string;
  grantType: string;
}

interface IPortal {
  name: string;
  logo: string;
  alias: string;
}

export default function Login() {
  const [portal, setPortal] = React.useState<IPortal>();
  const classes = useStyles();
  const location = useLocation();
  const params: IParams = useParams();
  const { alias, grantType } = params;
  const { autosubmit } = qs.parse(location.search);
  const sso = _.find(config.sso, ['grantType', grantType]);
  const variables = { alias };
  const controller = new window.AbortController();
  const context = { fetchOptions: { signal: controller.signal } };
  const results = useQuery(PORTAL_BY_ALIAS, { variables, context });

  const link = qs.stringifyUrl({
    url: `https://login.microsoftonline.com/${sso?.tenantId}/oauth2/v2.0/authorize`,
    query: {
      client_id: sso?.clientId,
      redirect_uri: `${document.location.origin}/${alias}/v2/sso/active-directory/${grantType}/redirect`,
      response_mode: 'query',
      response_type: 'code',
      scope: sso?.clientScope
    }
  });

  React.useEffect(() => {
    if (autosubmit) {
      controller.abort();
      window.location.href = link;
    }
  }, [autosubmit, link, controller]);

  React.useEffect(() => {
    if (results.data?.portalByAlias) {
      setPortal(results.data.portalByAlias);
      document.title = `Paymenthub - ${results.data.portalByAlias.name} Login`;
    }
  }, [results]);

  return (
      <form>
        <GraphqlAlert error={results.error} />
        {portal && <Typography variant="h5">{portal.name}</Typography>}
        <Typography variant="body1" className={classes.subtitle}>
          Log in with Azure Active Directory
        </Typography>
        {autosubmit && <CircularProgress color="primary" />}
        {!autosubmit && (
            <Button data-test="submit-login" className={classes.button} color="primary" href={link} variant="contained">
              Login
            </Button>
        )}
      </form>
  );
}
