import { ApolloError } from '@apollo/client';
import _ from 'lodash';
import React from 'react';

import Alert from './Alert';

interface IProps {
  error?: ApolloError;
}

/**
 * Renders alerts for each graphql error
 * @param props Error properties from Apollo Graph
 */

export default function GraphqlAlert(props: IProps) {
  const { error } = props;

  if (error?.graphQLErrors && !_.isEmpty(error?.graphQLErrors)) {
    return (
      <React.Fragment>
        {error.graphQLErrors.map((e, index) => (
          <Alert key={index} severity="error">
            {e.message}
          </Alert>
        ))}
      </React.Fragment>
    );
  }

  if (error?.networkError) {
    return <Alert severity="error">{error.networkError.message}</Alert>;
  }

  return null;
}
