import React from 'react';

import ApolloContextProvider from './Apollo';

/**
 * Context Provider wrapper to provided global state functionality
 * @param props Properties of a standard React Function Component
 */

interface IProps {
  children: React.ReactNode;
}

export default function GlobalContextProvider(props: IProps) {
  return <ApolloContextProvider>{props.children}</ApolloContextProvider>;
}
