import { useLazyQuery, useQuery } from '@apollo/client';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import qs from 'query-string';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';

import Alert from '../../components/Alert';
import GraphqlAlert from '../../components/GraphqlAlert';
import config from '../../config';
import { AUTHENTICATE_WITH_AZURE } from '../../graphql/auth';
import { PORTAL_BY_ALIAS } from '../../graphql/portals';

const useStyles = makeStyles({
  button: { padding: '12px 36px' },
  subtitle: { paddingBottom: 24 }
});

interface IParams {
  alias: string;
  grantType: string;
}

interface IPortal {
  name: string;
  logo: string;
  alias: string;
}

/**
 * Renders admin login form
 */

export default function RedirectHandler() {
  const [portal, setPortal] = React.useState<IPortal>();
  const classes = useStyles();
  const location = useLocation();
  const params: IParams = useParams();
  const { code, error, errorDescription: errorDescription } = qs.parse(location.search);
  const { alias, grantType } = params;
  const controller = new window.AbortController();
  const context = { fetchOptions: { signal: controller.signal } };
  const portalResults = useQuery(PORTAL_BY_ALIAS, { context, variables: { alias } });
  const sso = _.find(config.sso, ['grantType', grantType]);
  const [query, results] = useLazyQuery(AUTHENTICATE_WITH_AZURE);
  const redirectUrl = `${document.location.origin}/${alias}/v2/sso/active-directory/${grantType}/redirect`;

  const link = qs.stringifyUrl({
    url: `https://login.microsoftonline.com/${sso?.tenantId}/oauth2/v2.0/authorize`,
    query: {
      client_id: sso?.clientId,
      redirect_uri: redirectUrl,
      response_mode: 'query',
      response_type: 'code',
      scope: 'openid'
    }
  });

  // Handle Portal Query Results
  React.useEffect(() => {
    if (portalResults.data?.portalByAlias) {
      setPortal(portalResults.data.portalByAlias);
      document.title = `Paymenthub - ${portalResults.data.portalByAlias.name} Login`;
    }
  }, [portalResults]);

  // Query GraphQL for Token
  React.useEffect(() => {
    if (error) {
      // An error from Azure was received and the error message will display below
      return;
    }

    if (!code) {
      // To ensure safari evaluates cookies properly
      window.location.href = `${config.redirectAfterAuth}/${alias}`;
      return;
    }

    query({ variables: { code, alias, grantType, redirectUrl } });
  }, [code, error, query, alias, grantType, redirectUrl]);

  // Handle GraphQL Results
  React.useEffect(() => {
    if (!results.data?.authenticateWithAzure) {
      return;
    }

    const cookies = new Cookies();
    const { maxAge, phidToken } = results.data.authenticateWithAzure;
    cookies.set('phid', phidToken, { path: '/', maxAge, sameSite: 'strict', secure: true });
    controller.abort(); // Abort portal request if it has not finished

    // Safari requires a full page reload for the cookie to be set properly
    window.location.href = `${config.redirectAfterAuth}/${alias}`;
  }, [results, alias, controller]);

  return (
      <form>
        {portal && <Typography variant="h5">{portal.name}</Typography>}
        <Typography variant="body1" className={classes.subtitle}>
          Log in with Azure Active Directory
        </Typography>
        <GraphqlAlert error={portalResults.error} />
        <GraphqlAlert error={results.error} />
        {errorDescription && <Alert severity="error">{errorDescription}</Alert>}
        {results.loading && <CircularProgress color="primary" />}
        {(!code || (results.called && !results.loading && !results.data)) && (
            <Button data-test="submit-login" className={classes.button} color="primary" href={link} variant="contained">
              Login
            </Button>
        )}
      </form>
  );
}
