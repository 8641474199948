import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import Logo from '../../svg/Logo';
import Login from './Login';
import RedirectHandler from './RedirectHandler';

const useStyles = makeStyles({
  header: { textAlign: 'center' },
  logo: { width: '40%', height: '40%' },
  content: { textAlign: 'center', minHeight: 100 },
  actions: { display: 'block', textAlign: 'center' }
});

/**
 * Renders Azure login form
 */

export default function AzureLogin() {
  const classes = useStyles();
  const location = useLocation();
  const currentYear = new Date().getFullYear();

  return (
    <Card>
      <CardHeader className={classes.header} title={<Logo className={classes.logo} />} />
      <CardContent className={classes.content}>
        <Switch>
          <Route component={Login} exact path="/:alias/v2/sso/active-directory/:grantType" />
          <Route component={RedirectHandler} path="/:alias/v2/sso/active-directory/:grantType/redirect" />
          <Redirect to={`/error/404?path=${location.pathname}`} />
        </Switch>
      </CardContent>
      <CardActions className={classes.actions}>
        <Typography variant="caption" component="div">
            &copy; {currentYear} Exactuals, LLC
        </Typography>
      </CardActions>
    </Card>
  );
}
