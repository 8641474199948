const parsedOrigin = document.location.host.split('.');
const subdomain = parsedOrigin[0].toLowerCase();
// Exactuals Azure Ids
const clientIdMap = {
  dev: '5c3a5f13-e831-4978-8673-e1f34772d1a6',
  prod: 'bf29b5eb-7d01-41a7-977b-a5175c17ace1'
}
const tenantIdMap = {
  dev: 'eb15810a-8bf3-48f8-8c91-2cd59e4a7727',
  prod: '3a0fdda6-105d-4fce-bb8a-1ed83b71e72b'
}
// UTA Azure Ids
const utaClientIdMap = {
  dev: '92463f30-b6e5-4034-b872-796b31d05102',
  prod: '7d3345d5-714a-4edb-a438-cbc6c01ed3a9'
}
const utaTenantIdMap = {
  dev: '4cb9240e-2025-488f-97e7-7eb69335458a',
  prod: '4cb9240e-2025-488f-97e7-7eb69335458a'
}

// Active Directory - Exactuals
const exactualsProdAzureClientId = {
  dr: clientIdMap.prod,
  epu: clientIdMap.prod,
  epu5: clientIdMap.prod,
  paymenthub: clientIdMap.prod,
  prd5: clientIdMap.prod,
  stage: clientIdMap.prod,
  stg5: clientIdMap.prod
};
const exactualsProdAzureTenantId = {
  dr: tenantIdMap.prod,
  epu: tenantIdMap.prod,
  epu5: tenantIdMap.prod,
  paymenthub: tenantIdMap.prod,
  prd5: tenantIdMap.prod,
  stage: tenantIdMap.prod,
  stg5: tenantIdMap.prod
};

// Active Directory - UTA
// UTA clientId
const utaProdAzureClientId = {
  dr: utaClientIdMap.prod,
  epu: utaClientIdMap.prod,
  epu5: utaClientIdMap.prod,
  paymenthub: utaClientIdMap.prod,
  prd5: utaClientIdMap.prod,
  stage: utaClientIdMap.prod,
  stg5: utaClientIdMap.prod
};
// UTA Tenant ID
const utaProdAzureTenantId = {
  dr: utaTenantIdMap.prod,
  epu: utaTenantIdMap.prod,
  epu5: utaTenantIdMap.prod,
  paymenthub: utaTenantIdMap.prod,
  prd5: utaTenantIdMap.prod,
  stage: utaTenantIdMap.prod,
  stg5: utaTenantIdMap.prod
};

// CDN
const cdnMap = {
  dev: 'https://d3fgxs68f9cu42.cloudfront.net',
  prod: 'https://d21qxqrfzqgglp.cloudfront.net'
}
const cdn = {
  dr: cdnMap.prod,
  epu: cdnMap.prod,
  epu5: cdnMap.prod,
  paymenthub: cdnMap.prod,
  prd5: cdnMap.prod,
  stage: cdnMap.prod,
  stg5: cdnMap.prod,
};

const config = {
  sso: [
    {
      clientId: process.env.ENC_AZURE_PAYMENTCLIENT_CLIENTID || exactualsProdAzureClientId[subdomain] || clientIdMap.dev,
      tenantId: process.env.ENC_AZURE_TENANTID || exactualsProdAzureTenantId[subdomain] || tenantIdMap.dev,
      clientScope: process.env.ENC_AZURE_PAYMENTCLIENT_SCOPE || 'user.read profile openid',
      grantType: 'azure-ad',
      idp: 'active-directory'
    },
    {
      clientId: process.env.AZURE_UTA_CLIENT_ID || utaProdAzureClientId[subdomain] || utaClientIdMap.dev,
      tenantId: process.env.AZURE_UTA_TENANT_ID || utaProdAzureTenantId[subdomain] || utaTenantIdMap.dev,
      clientScope: process.env.AZURE_UTA_SCOPE || 'openid',
      grantType: 'uta-sso',
      idp: 'active-directory'
    }
  ],
  graphql: process.env.REACT_APP_GRAPH_API || `${document.location.origin}/graphql/gateway`,
  redirectAfterAuth: process.env.REACT_APP_REDIRECT_AFTER_AUTH || document.location.origin,
  cdnUrl: process.env.CDN_ASSETS_URL || cdn[subdomain] || cdnMap.dev
};

export default config;
