import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../svg/Logo';

const useStyles = makeStyles({
  actions: { display: 'block', textAlign: 'center' },
  caption: { paddingBottom: 12 },
  content: { textAlign: 'center', minHeight: 100 },
  header: { textAlign: 'center' },
  logo: { width: '40%', height: '40%' },
});

/**
 * Renders 404 Page
 */

export default function NoMatch() {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();

  return (
    <Card>
      <CardHeader className={classes.header} title={<Logo className={classes.logo} />} />
      <CardContent className={classes.content}>
        <Typography variant="h4" gutterBottom>
          Oh no!
        </Typography>
        <Typography className={classes.caption}>
          It appears the page you are looking for has been moved or does not exist.
        </Typography>
        <Typography className={classes.caption}>
          <Link to="https://www.exactuals.com">Please click here to return to the homepage</Link>
        </Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        <Typography variant="caption" component="div">
          &copy; {currentYear} Exactuals, LLC
        </Typography>
      </CardActions>
    </Card>
  );
}
