import { useLazyQuery } from '@apollo/client';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import React from 'react';
import Cookies from 'universal-cookie';

import GraphqlAlert from '../../../components/GraphqlAlert';
import config from '../../../config';
import { AUTHENTICATE_EXACTUALS_ADMIN_AS_PAYEE } from '../../../graphql/auth';
import { PAYEES } from '../../../graphql/portals';

const useStyles = makeStyles({
  center: { margin: 'auto' },
  search: { marginTop: 12, marginBottom: 12 }
});

interface IProps {
  search?: string | null;
}

interface IOrganization {
  name: string;
}

interface IPayee {
  altId: string;
  company: string;
  id: string;
  name: string;
  organization?: IOrganization;
}

interface IItem extends IPayee {
  authenticationInProgress: boolean;
  setAuthenticationInProgress: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Renders payee
 * @param props Properties from parent component
 */

function Payee(props: IItem) {
  const [query, results] = useLazyQuery(AUTHENTICATE_EXACTUALS_ADMIN_AS_PAYEE, { fetchPolicy: 'no-cache' });

  // Handle GraphQL Results
  React.useEffect(() => {
    if (results.loading) {
      props.setAuthenticationInProgress(true);
    }

    if (results.error) {
      props.setAuthenticationInProgress(false);
    }

    if (!results.data?.authenticateExactualsAdminAsPayee) {
      return;
    }

    const { alias, maxAge, phidToken } = results.data.authenticateExactualsAdminAsPayee;
    const cookies = new Cookies();
    cookies.set('phid', phidToken, { path: '/', maxAge, sameSite: 'strict' });

    window.location.href = `${config.redirectAfterAuth}/${alias}`;
  }, [results, props]);

  const handleClick = (payeeId: string) => () => {
    const variables = { payeeId };
    query({ variables });
  };

  const name = props.name || props.company || 'Unknown';
  return (
    <React.Fragment>
      {results.loading && <LinearProgress color="secondary" />}
      <GraphqlAlert error={results.error} />
      <ListItem key={props.id} divider button data-test="payee-list-item" onClick={handleClick(props.id)} disabled={props.authenticationInProgress}>
        <ListItemAvatar>
          <Avatar alt={name}>{name ? name.charAt(0).toUpperCase() : '?'}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={name}
          secondary={
            <React.Fragment>
              ID: {props.id}
              <br />
              Alt Payee ID: {props.altId}
              <br />
              Organization: {props.organization?.name}
            </React.Fragment>
          }
        />
      </ListItem>
    </React.Fragment>
  );
}

/**
 * Renders list of payees
 *
 * Conditional style was applied to the "CircularProgress", rather than render/no-render, to maintain a consistent height while fetching more data.
 * The "render/no-render" was causing a shifting page height while fetching data
 */

export default function Payees(props: IProps) {
  const classes = useStyles();
  const [query, results] = useLazyQuery(PAYEES, { errorPolicy: 'all' });
  const [payees, setPayees] = React.useState<IPayee[]>([]);
  const [search, setSearch] = React.useState<string>();
  const [authenticationInProgress, setAuthenticationInProgress] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [nextCursor, setNextCursor] = React.useState<string | undefined | null>();

  React.useEffect(() => {
    document.title = 'Paymenthub - Payees';
  }, []);

  React.useEffect(() => {
    const variables = { search };
    query({ variables });
  }, [search, query]);

  React.useEffect(() => {
    if (!results.data?.payees) {
      return;
    }

    setPayees(results.data.payees.nodes);
    setNextCursor(results.data.payees.pageInfo.nextCursor);
  }, [results]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value);
  }

  function fetchMore() {
    if (!nextCursor || !results.fetchMore) {
      return;
    }

    setLoading(true);
    results.fetchMore({
      variables: { after: nextCursor },
      updateQuery: (prev, options) => {
        setLoading(false);
        if (!options.fetchMoreResult || !options.fetchMoreResult.payees) {
          setNextCursor(null);
          return;
        }

        setPayees([...payees, ...options.fetchMoreResult.payees.nodes]);
        setNextCursor(options.fetchMoreResult.payees.pageInfo.nextCursor);
      }
    });
  }

  window.onscroll = _.debounce(() => {
    if (window.innerHeight + document.documentElement.scrollTop >= document.body.scrollHeight) {
      fetchMore();
    }
  }, 100);

  return (
    <React.Fragment>
      <TextField
        autoFocus
        className={classes.search}
        fullWidth
        onChange={handleChange}
        placeholder="Search for a payee"
        value={search || ''}
        variant="outlined"
      />
      <List>
        <GraphqlAlert error={results.error} />
        {!results.loading &&
          payees.map((payee, index) => (
            <Payee
              {...payee}
              authenticationInProgress={authenticationInProgress}
              key={index}
              setAuthenticationInProgress={setAuthenticationInProgress}
            />
          ))}
        {results.called && _.isEmpty(payees) && !results.error && !results.loading && (
          <ListItem divider>
            <ListItemText primary="No records found. Please modify search criteria." />
          </ListItem>
        )}
        {nextCursor && !results.loading && !loading && (
          <ListItem>
            <Button variant="outlined" onClick={fetchMore} className={classes.center} color="primary">
              Load More
            </Button>
          </ListItem>
        )}
        {(results.loading || loading) && (
          <ListItem>
            <CircularProgress color="primary" className={classes.center} />
          </ListItem>
        )}
      </List>
    </React.Fragment>
  );
}
