import config from "./index";

function AssetUrl(imagePath: string) {
    const { cdnUrl } = config
    if(imagePath.includes('http')) {
        return imagePath;
    } else if(imagePath.charAt(0) === '/') {
        return `${cdnUrl}${imagePath}`;
    } else {
        return `${cdnUrl}/${imagePath.substring(1)}`;
    }
}

export default AssetUrl;