import { gql } from '@apollo/client';

export const AUTHENTICATE_EXACTUALS_WITH_AZURE = gql`
  query authenticateExactualsWithAzure($code: String!, $redirectUrl: String!) {
    authenticateExactualsWithAzure(code: $code, redirectUrl: $redirectUrl) {
      isExactualsAdmin
      maxAge
      phidToken
      roles
    }
  }
`;

export const AUTHENTICATE_EXACTUALS_ADMIN_AS_PAYER = gql`
  query authenticateExactualsAdminAsPayer($organizationId: String!) {
    authenticateExactualsAdminAsPayer(organizationId: $organizationId) {
      alias
      isExactualsAdmin
      maxAge
      phidToken
      roles
    }
  }
`;

export const AUTHENTICATE_EXACTUALS_ADMIN_AS_PAYEE = gql`
  query authenticateExactualsAdminAsPayee($payeeId: String!) {
    authenticateExactualsAdminAsPayee(payeeId: $payeeId) {
      alias
      isExactualsAdmin
      maxAge
      phidToken
      roles
    }
  }
`;

export const AUTHENTICATE_WITH_AZURE = gql`
  query authenticateWithAzure($code: String!, $alias: String!, $grantType: String!, $redirectUrl: String!) {
    authenticateWithAzure(code: $code, alias: $alias, grantType: $grantType, redirectUrl: $redirectUrl) {
      maxAge
      phidToken
      roles
    }
  }
`;

export const AUTHENTICATE_WITH_PORTAL = gql`
  query authenticateWithPortal($username: String!, $password: String!, $portal: String!) {
    authenticateWithPortal(username: $username, password: $password, portal: $portal) {
      maxAge
      phidToken
    }
  }
`;
