import './App.css';

import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import theme from './config/theme';
import GlobalContextProvider from './context/Global';
import Router from './views/Router';

/**
 * Provides global context and renders application router
 */

export default function App() {
  return (
    <GlobalContextProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </ThemeProvider>
    </GlobalContextProvider>
  );
}
